import DetailMath from "components/detail-game/detail-math/DetailMath";
import DetailNeckThrow from "components/detail-game/detail-neck-throw/DetailNeckThrow";
import NeckThrowSessionDetail from "components/detail-game/detail-neck-throw/NeckThrowSessionDetail";
import DetailPuzzles from "components/detail-game/detail-puzzles/DetailPuzzles";
import DetailPuzzlesSession from "components/detail-game/detail-puzzles/DetailPuzzlesSession";
import DetailTmt from "components/detail-game/detail-tmt/DetailTmt";
import { usePatient } from "contexts/patient-context/PatientContext";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Popup from "reactjs-popup";

import LeftArrowIcon from "assets/icons/left-arrow.svg";

import { RootState } from "appdata/store";
import DetailBasketball from "components/detail-game/detail-basketball/DetailBasketball";
import DetailFishHunter from "components/detail-game/detail-fish-hunter/DetailFishHunter";
import DetailFlipFood from "components/detail-game/detail-flip-food/DetailFlipFood";
import DetailFruitConnect from "components/detail-game/detail-fruit-connect/DetailFruitConnect";
import DetailFruitPicker from "components/detail-game/detail-fruit-picker/DetailFruitPicker";
import DetailFruitSorter from "components/detail-game/detail-fruit-sorter/DetailFruitSorter";
import DetailHandShaper from "components/detail-game/detail-hand-shaper/DetailHandShaper";
import DetailJumpFrog from "components/detail-game/detail-jump-frog/DetailJumpFrog";
import DetailLineWorker from "components/detail-game/detail-line-worker/DetailLineWorker";
import DetailMemoryCard from "components/detail-game/detail-memory-card/DetailMemoryCard";
import Loader from "components/Loader/Loader";
import { useCallback } from "react";
import styles from "./PatientDetailPopup.module.scss";

interface PatientDetailPopupProps {
  openModal: boolean;
  setOpenModal: (open: boolean) => void;
}

const PatientDetailPopup: React.FC<PatientDetailPopupProps> = ({
  openModal,
  setOpenModal,
}) => {
  const { t } = useTranslation();

  const {
    patientId,
    results,
    selectedGameId,
    sessionDetailSelected,
    setSelectedGameId,
    setSessionDetailSelected,
    setAnalyticsGameOfPatient,
  } = usePatient();

  const patientsRedux = useSelector((state: RootState) => state.patientsRedux);
  const gamesRedux = useSelector((state: RootState) => state.gamesRedux);

  const closeModal = () => {
    setSelectedGameId(null);
    setSessionDetailSelected(null);
    setOpenModal(false);
  };

  const switchGameDetails = useCallback(() => {
    const gameConfigName = gamesRedux.games?.find(
      (el) => el.id === selectedGameId
    )?.configName;

    switch (gameConfigName) {
      case "tmt_a":
        return <DetailTmt />;
      case "tmt_b":
        return <DetailTmt />;
      case "puzzles":
        return <DetailPuzzles />;
      case "neck_throw":
        return <DetailNeckThrow />;
      case "math":
        return <DetailMath />;
      case "hand_shaper":
        return <DetailHandShaper />;
      case "line_worker":
        return <DetailLineWorker />;
      case "memory_card":
        return <DetailMemoryCard />;
      case "flip_food":
        return <DetailFlipFood />;
      case "jump_frog":
        return <DetailJumpFrog />;
      case "basketball":
        return <DetailBasketball />;
      case "fruit_picker":
        return <DetailFruitPicker />;
      case "fruit_picker_japan_test":
        return <DetailFruitPicker />;
      case "fruit_sorter":
        return <DetailFruitSorter />;
      case "fruit_connect":
        return <DetailFruitConnect />;
      case "fish_hunter":
        return <DetailFishHunter />;
      default:
        return <></>;
    }
  }, [gamesRedux.games, selectedGameId]);

  const renderSessionDetails = useCallback(() => {
    const gameConfigName = gamesRedux.games?.find(
      (el) => el.id === selectedGameId
    )?.configName;

    switch (gameConfigName) {
      case "puzzles":
        return <DetailPuzzlesSession />;
      case "neck_throw":
        return <NeckThrowSessionDetail />;
      default:
        return <></>;
    }
  }, [gamesRedux.games, selectedGameId]);

  return (
    <Popup
      contentStyle={{ zIndex: 1001 }}
      open={openModal}
      modal
      onClose={closeModal}
      closeOnDocumentClick={false}
    >
      <div
        className={`${styles.patientDetailPopup} ${
          sessionDetailSelected && styles.active
        }`}
      >
        <div className={styles.header_popup}>
          <span className="size_large text_gradient">
            {`${t("dashboard_page.practiceResult")} - ${
              patientsRedux.patientsOfDoctor?.find((el) => el.id === patientId)
                ?.username
            }`}
          </span>
          <button
            className={`${styles.close_btn} size_small`}
            onClick={closeModal}
          >
            &times;
          </button>
        </div>

        <div className={styles.line}></div>
        <div className={styles.body_popup}>
          <div style={{ display: "flex", flexDirection: "column", flex: "1" }}>
            <div className={styles.content_popup}>
              <div className={styles.left_content}>
                <div className={`${styles.game_item} button_gradient`}>
                  GAME
                </div>
                <div className={styles.games_container}>
                  {gamesRedux.games?.map((game, index) => (
                    <div
                      key={index}
                      className={`${styles.game_item} ${
                        game.id === selectedGameId ? `${styles.active}` : ""
                      }`}
                      onClick={() => {
                        setSelectedGameId(game.id);
                        setSessionDetailSelected(null);
                        setAnalyticsGameOfPatient(null);
                      }}
                    >
                      {game.name}
                    </div>
                  ))}
                </div>
              </div>
              {
                <div className={styles.game_detail_wrapper}>
                  <Loader isLoading={selectedGameId !== results?.gameId} />
                  {selectedGameId === results?.gameId && (
                    <div className={styles.gameDetails}>
                      {switchGameDetails()}
                    </div>
                  )}
                </div>
              }
            </div>
          </div>

          <div
            className={`${styles.session_detail} ${
              sessionDetailSelected && styles.active
            }`}
          >
            <button
              onClick={() => {
                setSessionDetailSelected(null);
              }}
            >
              <img
                style={{
                  objectFit: "cover",
                }}
                src={LeftArrowIcon}
                alt="LeftArrowIcon"
              />
              <span>{t("table.viewDetails")}</span>
            </button>

            {sessionDetailSelected && renderSessionDetails()}
          </div>
        </div>
      </div>
    </Popup>
  );
};

PatientDetailPopup.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
};

export default PatientDetailPopup;
