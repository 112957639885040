import { getPatientsOfDoctor } from "appdata/patients/patientsSlice";
import { AppDispatch, RootState } from "appdata/store";
import PaginationCus from "components/PaginationCus/PaginationCus";
import TableCus from "components/TableCus/TableCus";
import { StyledItem } from "components/TableSticky/TableSticky";
import { usePatient } from "contexts/patient-context/PatientContext";
import PatientDetailPopup from "pages/Dashboard/PatientDetailPopup";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { User } from "types/userTypes";

interface DataTableRow {
  key: string | number;
  patientId: string;
  fullName: string;
  gender: string;
  age: number | string;
  mmse: number | string;
  detail: JSX.Element;
}

function PatientsOfDoctor() {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const { patientId, setPatientId, setSelectedGameId } = usePatient();

  const gamesRedux = useSelector((state: RootState) => state.gamesRedux);
  const patientsRedux = useSelector((state: RootState) => state.patientsRedux);

  const headTabs = [
    { key: "patientId", label: t("common_text.patientId") },
    { key: "fullName", label: t("common_text.fullName") },
    { key: "mmse", label: "MMSE" },
    { key: "gender", label: t("common_text.gender") },
    { key: "age", label: t("common_text.age") },
    { key: "detail", label: t("table.viewDetails") },
  ];
  const headerStyles: StyledItem[] = [
    { key: "patientId", style: { fontWeight: "900" } },
    { key: "fullName", style: { textAlign: "center", fontWeight: "900" } },
    { key: "mmse", style: { fontWeight: "900" } },
    { key: "gender", style: { fontWeight: "900" } },
    { key: "age", style: { fontWeight: "900" } },
    { key: "detail", style: { fontWeight: "900" } },
  ];

  const columnStyles: StyledItem[] = [
    {
      key: "fullName",
      style: { textAlign: "center", color: "var(--text-color-blue)" },
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [openPatientDetailModal, setOpenPatientDetailModal] = useState(false);

  const convertDataToDataTable = (
    _data: User[] | null
  ): DataTableRow[] | [] => {
    if (!_data) return [];

    return _data.map((user, key) => {
      return {
        key: user.id ?? key,
        patientId: user.username ?? "",
        fullName: user.name ?? "",
        mmse: user.mmse ?? t("common_text.unassessed"),
        gender: t(`common_text.${user.gender}`) ?? "",
        age: user.age ?? "",
        detail: (
          <button
            style={{
              color: "var(--text-color-blue)",
              textDecorationLine: "underline",
              fontSize: "1vw",
            }}
            onClick={() => {
              setPatientId(user.id ?? null);
              setSelectedGameId(gamesRedux.games?.[0]?.id ?? null);
              setOpenPatientDetailModal(true);
            }}
          >
            {t("table.viewDetails")}
          </button>
        ),
      };
    });
  };

  return (
    <div
      style={{
        flex: "1",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <TableCus
        headTabs={headTabs}
        data={convertDataToDataTable(patientsRedux.patientsOfDoctor)}
        headerStyles={headerStyles}
        columnStyles={columnStyles}
      ></TableCus>
      <PaginationCus
        metadataPage={patientsRedux.metadataPatientsOfDoctorPage}
        onClick={(pageParam) => {
          dispatch(
            getPatientsOfDoctor({
              ...pageParam,
            })
          );
          setCurrentPage(pageParam.page);
        }}
        styles={{
          marginTop: "auto",
          marginBottom: "20px",
          flexShrink: 0,
          paddingTop: "20px",
        }}
      />
      {patientId && openPatientDetailModal && (
        <PatientDetailPopup
          openModal={openPatientDetailModal}
          setOpenModal={setOpenPatientDetailModal}
        />
      )}
    </div>
  );
}

export default PatientsOfDoctor;
